import { Moment } from 'moment';
import { round } from '../shared/utils/utils';
import { Company, DepartmentTypeEnum } from './shared.model';
import { CompanyDimensionsLabelsStructure, Manager, SupportedLanguages } from '.';
import { LanguageEnum } from '@howdy/models';

export abstract class Metric {
  Answer1AverageScore: number;
  Answer2AverageScore: number;
  Answer3AverageScore: number;
  Answer4AverageScore: number;
  Answer5AverageScore: number;
  AvgScorePerAnswer: number;
  DepartmentId: number;
  Department: string;
  Division: string;
  Project: string;
  CompanyId?: number;
  BarometerDegree: number;
  NoOfEmployees: number;
  MonthStart: Moment;
  CategoryDarkGreen: number;
  CategoryMediumGreen: number;
  CategoryLightGreen: number;
  CategoryYellow: number;
  CategoryRed: number;
  CategoryNoAnswer: number;
  CategoryDarkGreenAverageScore: number;
  CategoryMediumGreenAverageScore: number;
  CategoryLightGreenAverageScore: number;
  CategoryYellowAverageScore: number;
  CategoryRedAverageScore: number;
  HowdyScore: number;
  StateActive: number;
  StateInvited: number;
  StateOptout: number;
  Participation: number;
  ParticipationLast3Months: number;
  ParticipationLastYear: number;
  ActiveLastYear: number;
  ActiveLast3Months: number;
  OpenCases: number;
  ClosedCases: number;
  CasesWithContact: number;
  EmployeesWithClosedCaseLastYear: number;
  EmployeesWithContactLastYear: number;
  BodyOpenCases: number;
  BodyClosedCases: number;
  BodyCasesWithContact: number;
  TeamOpenCases: number;
  TeamClosedCases: number;
  TeamCasesWithContact: number;
  ManagersWithClosedCaseLastYear: number;
  ManagersWithContactLastYear: number;
  ManagersWithClosedCaseThisYear: number;
  ManagersWithContactThisYear: number;
  EmployeesWithClosedCaseLastYearBody: number;
  EmployeesWithContactLastYearBody: number;
  EmployeesWithClosedCaseThisYearBody: number;
  EmployeesWithContactThisYearBody: number;
  EmployeesWithClosedCaseThisYearWellbeing: number;
  EmployeesWithContactThisYearWellbeing: number;
  EmployeesWithClosedCaseLastYearWellbeing: number;
  EmployeesWithContactLastYearWellbeing: number;
  ForecastYearAllWellbeing?: number;
  ForecastYearAllBody?: number;
  ForecaseQuarterAllWellbeing?: number;
  ForecaseQuarterAllBody?: number;
  Months: number;
  static getParticipationPercentage = (metric: Metric) => {
    return metric.StateActive ? metric.Participation / metric.StateActive : 0;
  };
}

export interface DepartmentCounts {
  MonthStart: Moment;
  DepartmentCount: number;
}

export interface DepartmentDropdownByType {
  [type: string]: DepartmentDropdownData[];
}

export interface DepartmentDropdownData {
  Id: number;
  Name: string;
  DepartmentId?: number;
  Type?: DepartmentTypeEnum;
  SortingScore?: number;
  HasData?: boolean;
  HasUsers?: boolean;
  IsDisabled?: boolean;
  TooltipText?: string;
  Managers?: Manager[];
  Color?: IndexMovementColors;
}

export interface InterventionData {
  Period: string;
  AverageConversationEval: number;
  IsBody: boolean;
  Count: number;
}

export interface SelectedDepartmentDropdownDataIds {
   [departmentId: number]: boolean;
}

export interface CaseDetails {
  NumberOfContactCompanyLevel: { [key: number]: number };
  NumberOfContactBenchmark: number[];
  NumberOfCasesCompanyLevel: { [key: number]: number };
  NumberOfCasesBenchmark: number[];
  NumberOfRegisteredCompany: number[];
  NumberOfOpenCasesCompany: { [key: number]: number };
  NumberOfEmployeesWithCaseCompany: number;
  NumberOfEmployeesWithContactCompany: number;
  Months: string[];
}

export interface DashboardData {
  metrics: Metric[];
  departmentCounts: DepartmentCounts[];
  industryBenchmark: WellbeingBenchmark[];
  countryBenchmark: WellbeingBenchmark[];
  casesBenchmark: CasesBenchmark[];
  wellbeingIntervals: WellbeingIntervals;
  wellbeingIndexIntervals: WellbeingIndexInterval[];
}

export interface WellbeingBenchmark {
  MonthStart: Moment;
  CategoryDarkGreen: number;
  CategoryMediumGreen: number;
  CategoryLightGreen: number;
  CategoryYellow: number;
  CategoryRed: number;
  Answer1: number;
  Answer2: number;
  Answer3: number;
  Answer4: number;
  Answer5: number;
  HowdyScore: number;
  Index: number;
  Months: number;
}

export interface CasesBenchmark {
  MonthStart: Moment;
  ClosedCases: number;
  BodyClosedCases: number;
  TeamClosedCases: number;
  AllClosedCases: number;
  EmployeesWithClosedCaseThisYearWellbeing: number;
  EmployeesWithClosedCaseThisYearBody: number;
  ManagersWithClosedCaseThisYear: number;
  Months: number;
}

export interface DepartmentCaseTab {
  title: string;
  data: DepartmentCaseGroup[];
  isEmpty: boolean;
  emptyTitle: string;
}


export interface TabContent {
  states: DepartmentCaseState[];
  label: string;
  tab: DepartmentCaseTab;
}

export enum DepartmentCaseState {
  MissingPrimaryContact = 1,
  MissingManager = 2,
  NoBookingMade = 3,
  NoBookingMadeManager = 4,
  InvitedForCoaching = 5,
  CallScheduled = 6,
  CallCompleted = 7,
  CaseClosed = 8,
  OpenReminderSent = 9
}

export interface DepartmentCaseGroup {
  GroupId: number;
  IsOpen: boolean;
  Name: string;
  Cases: DepartmentCase[];
}

export interface DepartmentCaseManager {
  Name: string;
  IsPrimaryContact: boolean;
}

export interface DepartmentCase {
  Id: number;
  CaseId: number;
  GroupId: number;
  CompanyId?: number;
  CompanyName?: string;
  State: DepartmentCaseState;
  DepartmentId: number;
  DepartmentName: string;
  Manager: DepartmentCaseManager | DepartmentCaseManager[];
  Date: Moment;
  Month: Moment;
  DateLabel: string;
  DateTooltip: string;
  HowdyScore: number;
  Index: number;
  ButtonText?: string;
  WellbeingIntervals?: WellbeingIntervals;
}

export interface BarItem {
  backgroundColor: string;
  value: number;
}
export interface DepartmentOperationsActions {
  Label: string;
  Icon: string;
  Enabled: boolean;
  Show?: boolean;
  Action: () => void;
}

export interface EmployeeDistribution {
  red: EmployeeDistributionCategory;
  yellow: EmployeeDistributionCategory;
  lightGreen: EmployeeDistributionCategory;
  green: EmployeeDistributionCategory;
  darkGreen: EmployeeDistributionCategory;
}

export interface EmployeeDistributionCategory {
  icon: string;
  color: string;
  value: number;
}

export interface ActionBoardData {
  LoginStreak: LoginStreak;
  UserActions: UserAction[];
  Reminder: Reminder;
}

export interface LoginStreak {
  IsFirstLogin: boolean;
  MonthStreak: number;
  LastFiveMonths: MonthlyLoginStatus[];
}

export interface MonthlyLoginStatus {
  Month: Moment;
  LoggedIn: boolean;
}

export interface UserAction {
  Action: EffortType;
  State: EffortState;
  Date?: Moment;
  Link?: string;
  HaveNotAnsweredCount?: number;
  TotalEmployeeCount?: number;
  MessageCount?: number;
  Agent?: string;
}

export interface Reminder {
  AssignedPsychologist: string;
  Link: string;
  Date: Moment;
}

export interface Effort {
  Id: number;
  Title: string;
  Description: string;
  Action: string;
  EffortType: EffortType;
  Link?: string;
  ButtonText?: string;
  NumberOfMessages?: number;
  HaveNotAnsweredCount?: number;
  TotalEmployeeCount?: number;
  State?: EffortState;
  CompanyName?: string;
  Agent?: string;
  Date: Moment;
}

export interface ForecastTooltip {
  currentValue: number;
  currentPeriod: string;
  period: string;
  forecastValue: number;
}

export enum EffortState {
  Todo = 0,
  Completed = 1,
  Skipped = 2
}

export enum EffortType {
  WellbeingOnAgenda = 1,
  ConversationWithEmployees = 2,
  SharedLatestData = 3,
  ImportanceOfParticipation = 4,
  Heatmap = 5,
  NewMessages = 6,
  CoachingSessionInvited = 7, // invited for coaching season, but not scheduled
  CoachingSessionCanCreate = 8, // can create coaching session for this user
  CoachingSessionScheduled = 10, // coaching session scheduled
}

export enum IndexMovementColors {
  Red = '#E3645F',
  Yellow = '#FFD545',
  Green = '#81D4BD',
  Purple = '#B099CC',
  Blue = '#00ABE4'
}

export enum DepartmentOperationsActionsEnum {
  CreateCase = 1,
  AddManager = 2,
  AddPrimaryContact = 3
}

export enum WellbeingIndexCategories {
  Low = 0,
  Normal = 1,
  High = 2
}

export enum WellbeingCategories {
  DarkGreen = 0,
  MediumGreen = 1,
  LightGreen = 2,
  Yellow = 3,
  Red = 4
}

export enum WellbeingCategoriesColors {
  DarkGreen = '#32991e',
  MediumGreen = '#53b223',
  LightGreen = '#7acc28',
  Yellow = '#ffec26',
  Red = '#d82b2b'
}

export enum DataLabelChartType {
  Wellbeing = 1,
  Answer = 2
}

export abstract class WellbeingIndexInterval {
  Start: number;
  End: number;
  MinScore: number;
  MaxScore: number;
}

export abstract class WellbeingInterval {
  Start: number;
  End: number;
  MinScore: number;
  MaxScore: number;
  private Color: WellbeingCategories;

  static contains = (score: number, interval: WellbeingInterval) => interval.MinScore <= score && score <= interval.MaxScore;

  static containsWellbeing = (wellbeing: number, interval: WellbeingInterval) => interval.Start <= wellbeing && wellbeing < interval.End;

  static getColor = (interval: WellbeingInterval) => {
    const categoryIndex = interval.Color;
    const colorKey = WellbeingCategories[categoryIndex];
    const color = WellbeingCategoriesColors[colorKey];
    return color;
  };

  static getCategory = (interval: WellbeingInterval) => {
    const categoryIndex = interval.Color;
    const categoryKey = WellbeingCategories[categoryIndex];
    return categoryKey;
  };

  static getScoreFromWellbeing = (wellbeing: number, interval: WellbeingInterval) => {
    const range = interval.MaxScore - interval.MinScore;
    const decimalPart = (wellbeing - interval.Start) / (interval.End - interval.Start);
    return WellbeingInterval.containsWellbeing(wellbeing, interval) ? round(interval.MinScore + (range * decimalPart), 1) : 0;
  };
}

export abstract class WellbeingIntervals extends Array<WellbeingInterval> {
  static getColor = (score: number, intervals: WellbeingIntervals) => {
    const interval = intervals.find(x => WellbeingInterval.contains(score, x));
    return interval ? WellbeingInterval.getColor(interval) : null;
  };

  static getCategory = (score: number, intervals: WellbeingIntervals) => {
    const interval = intervals.find(x => WellbeingInterval.contains(score, x));
    return interval ? WellbeingInterval.getCategory(interval) : null;
  };

  static getScoreFromWellbeing = (wellbeing: number, intervals: WellbeingIntervals) => {
    const interval = intervals.find(x => WellbeingInterval.containsWellbeing(wellbeing, x));
    return interval ? WellbeingInterval.getScoreFromWellbeing(wellbeing, interval) : null;
  };
}

export function groupDepartmentsDropdownByType(
  departments: DepartmentDropdownData[],
  company: Company,
  languageCode: string,
  dimensionsLabels: CompanyDimensionsLabelsStructure
): DepartmentDropdownByType {
  if (!company) {
    return {};
  }

  const languageId = SupportedLanguages.find(x => x.code === languageCode)?.id;
  const withData = departments.filter(x => x.HasData).sort((a, b) => a.Name.localeCompare(b.Name));
  const withoutData = departments.filter(x => !x.HasData).sort((a, b) => a.Name.localeCompare(b.Name));

  return [...withData, ...withoutData]
    .filter(department => department.DepartmentId)
    .sort((a, b) => a.Type - b.Type)
    .reduce((acc, item) => {
      const fallbackLanguage = LanguageEnum.English;
      const defaultLanguage = company.DefaultLanguageId;
      const finalNames = [];
      const bucketName = company.DepartmentDimensions[DepartmentTypeEnum[item.Type]];

      if (!bucketName) {
        return {};
      }

      const bucketNameArray = bucketName.split(',').map((x: string) => x.replace(/ /g, ''));

      if (!!bucketNameArray.length) {
        if (!dimensionsLabels[bucketNameArray[0]]) {
          return {};
        }

        for (const k of bucketNameArray) {
          const finalName = !!dimensionsLabels[k][languageId]
            ? dimensionsLabels[k][languageId]
            : !!dimensionsLabels[k][fallbackLanguage]
              ? dimensionsLabels[k][fallbackLanguage]
              : dimensionsLabels[k][defaultLanguage];
          finalNames.push(finalName);
        }
      } else {
        finalNames.push(bucketName);
      }

      const withoutUndef = finalNames.filter(finalName => finalName !== undefined);
      const translatedBucketName = withoutUndef.join(', ');
      const bucket = acc[translatedBucketName] || [];

      return { ...acc, [translatedBucketName]: bucket.concat(item) };
    }, {});
}

export type DepartmentMetricNonAdditiveKeys = Pick<Metric,
  'CompanyId' |
  'DepartmentId' |
  'StateInvited' |
  'StateActive' |
  'EmployeesWithContactThisYearWellbeing' |
  'EmployeesWithContactThisYearBody' |
  'EmployeesWithClosedCaseThisYearWellbeing' |
  'EmployeesWithClosedCaseThisYearBody'|
  'ManagersWithContactThisYear' |
  'ManagersWithClosedCaseThisYear'
>;
