import { Metric } from './dashboard.model';

export interface Heatmap {
  CompanyId: number;
  DepartmentId: number;
  DepartmentName: string;
  DepartmentType: number;
  HeatLevel: HeatLevelEnum;
  PrivateWorkRelatedHeatLevel: HeatLevelEnum;
  Percentage: number;
  MonthStart: string;
  Months: number;
  Outcome: number;
  OutcomeGroup: OutcomeGroupEnum;
}

export interface HeatmapStructure {
  [outcomeGroup: string]: {
    [outcome: string]: {
      [monthStart: string]: Heatmap;
    };
  };
}

export type HeatmapMetrics = Pick<Metric,
  'DepartmentId' |
  'CompanyId' |
  'MonthStart' |
  'Participation' |
  'EmployeesWithClosedCaseLastYearBody' |
  'Months'
>;

export interface HeatmapMetricsStructure {
  [monthStart: string]: HeatmapMetrics & { Participation: number };
}

export enum HeatLevelEnum {
  HeatLevel1 = 1,
  HeatLevel2 = 2,
  HeatLevel3 = 3,
  HeatLevel4 = 4,
  HeatLevel5 = 5,
  HeatLevel6 = 6,
  HeatLevel7 = 7,
  HeatLevel8 = 8,
  HeatLevel9 = 9,
  HeatLevel10 = 10,
}

export const HeatLevelColorScheme: { [K in HeatLevelEnum]?: string } = {
  [HeatLevelEnum.HeatLevel1]: '#FFD6D6',
  [HeatLevelEnum.HeatLevel2]: '#FFC2C2',
  [HeatLevelEnum.HeatLevel3]: '#FF9999',
  [HeatLevelEnum.HeatLevel4]: '#FF7070',
  [HeatLevelEnum.HeatLevel5]: '#FF4747',
  [HeatLevelEnum.HeatLevel6]: '#FF1F1F',
  [HeatLevelEnum.HeatLevel7]: '#F50000',
  [HeatLevelEnum.HeatLevel8]: '#CC0000',
  [HeatLevelEnum.HeatLevel9]: '#A80000',
  [HeatLevelEnum.HeatLevel10]: '#850000'
};

export enum OutcomeGroupEnum {
  Workrelated,
  Private,
  NoContact,
  Other
}

export interface CaseOutcomes {
  Outcome: number;
  OutcomeName: string;
  OutcomeGroup: number;
  OutcomeGroupName: string;
  OutcomeTooltip: string;
  Language: string;
  IsBody: boolean;
}
